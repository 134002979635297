<section class="footer-section pt-100 pb-70">
    <div class="container">
        <div class="subscribe-area">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="subscribe-content">
                        <h2>Join Our Newsletter</h2>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                        <button disabled type="">Subscribe Now</button>
                    </form>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>About Us</h3>
                    </div>
                    <p>DevU is a software startup specializing in web application and mobile application development. The company leverages the latest technologies and tools to deliver the best results and services to its clients.
                    </p>
                    <ul class="footer-social">
                        <li><a ><i class="flaticon-facebook"></i></a></li>
                        <li><a><i class="flaticon-twitter"></i></a></li>
                        <li><a ><i class="flaticon-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Important Links</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/projects">Project</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Featured Service</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">IT Management</a></li>
                        <li><a routerLink="/">Development</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <li><a routerLink="/">UI/UX Design</a></li>
                        <li><a routerLink="/">Support Engineer</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Contact</h3>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-phone-call"></i>
                        <h3>Phone</h3>
                        <span><a href="tel:+201064122340">+201064122340</a></span>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-envelope"></i>
                        <h3>Email</h3>
                        <span><a href="mailto:it@devu-soft.com">it@devu-soft.com</a></span>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-pin"></i>
                        <h3>Address</h3>
                        <span><a >Nasr City,Cairo,Egypt</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-area-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p><i class='bx bx-copyright'></i>2023 DevU. All Rights Reserved by <a href="https://devu-soft.com/" target="_blank">DevU</a></p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li>Terms & Conditions</li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="go-top"><i class="bx bx-chevron-up"></i><i class="bx bx-chevron-up"></i></div>

<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-about-area">
            <div class="title">
                <h2>About Us</h2>
                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
            </div>
        </div>
        <div class="sidebar-contact-feed">
            <h2>Contact</h2>
            <div class="contact-form">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Your Subject">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Your Message"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="send-btn">
                                <button type="submit" class="send-btn-one">Send Message</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="sidebar-contact-area">
            <div class="contact-info">
                <div class="contact-info-content">
                    <h2>
                        <a href="tel:+0881306298615">+088 130 629 8615</a>
                        <span>OR</span>
                        <a href="mailto:hello@avrax.com">hello@avrax.com</a>
                    </h2>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-pinterest"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <span class="close-btn sidebar-modal-close-btn">
            <i class="flaticon-cancel"></i>
        </span>
    </div>
</div>
