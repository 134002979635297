<app-navbar-two></app-navbar-two>

<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-section pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="about-image">
                    <img src="assets/img/logo65sd.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-tab">
                    <h2>
                        About
                        <span style="color: #086ad8; font-weight: 900">
                            DevU</span
                        >
                    </h2>
                    <div class="bar"></div>
                    <p>
                        DEVU is a software startup that specializes in providing
                        cutting-edge solutions in web development, app
                        development, WordPress and Shopify, digital marketing,
                        and IT consultancy.
                    </p>
                    <div class="tab about-list-tab">
                        <ul class="tabs">
                            <li><a href="#">Our History</a></li>
                            <li><a href="#">Our Missinon</a></li>
                            <li><a href="#">Friendly Support</a></li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>
                                        Welcome to DEVU - Your Partner for
                                        Innovative Technology Solutions.
                                    </h3>
                                </div>
                                <ul class="list">
                                    <li>
                                        <i class="flaticon-tick"></i> Innovative
                                        Solutions: Tech-savvy, customized
                                        options.
                                    </li>
                                    <li>
                                        <i class="flaticon-tick"></i> Skilled
                                        Professionals: Passionate, experienced
                                        team members.
                                    </li>
                                    <li>
                                        <i class="flaticon-tick"></i>
                                        Comprehensive Services: Web, app,
                                        digital, IT solutions.
                                    </li>
                                    <li>
                                        <i class="flaticon-tick"></i>
                                        Results-Driven Approach: Measurable
                                        outcomes, ROI focus.
                                    </li>
                                </ul>
                                <p>
                                    DEVU is a young and dynamic software startup
                                    that was founded just one year ago. Despite
                                    being a relatively new player in the
                                    industry, we have quickly established
                                    ourselves as a leading provider of
                                    innovative and customizable solutions in web
                                    development, app development, WordPress and
                                    Shopify, digital marketing, and IT
                                    consultancy.
                                </p>
                                <a class="default-btn" routerLink="/contact"
                                    >Contact Us</a
                                >
                            </div>
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>
                                        Welcome to DEVU - Your Partner for
                                        Innovative Technology Solutions.
                                    </h3>
                                </div>
                                <ul class="list">
                                    <li>
                                        <i class="flaticon-tick"></i> Innovative
                                        Solutions: Tech-savvy, customized
                                        options.
                                    </li>
                                    <li>
                                        <i class="flaticon-tick"></i> Skilled
                                        Professionals: Passionate, experienced
                                        team members.
                                    </li>
                                    <li>
                                        <i class="flaticon-tick"></i>
                                        Comprehensive Services: Web, app,
                                        digital, IT solutions.
                                    </li>
                                    <li>
                                        <i class="flaticon-tick"></i>
                                        Results-Driven Approach: Measurable
                                        outcomes, ROI focus.
                                    </li>
                                </ul>
                                <p>
                                    Our mission at DEVU is to empower businesses with innovative technology solutions to achieve success in the digital age. We aim to provide exceptional customer service and deliver high-quality, customizable solutions that meet our clients' unique needs and challenges
                                </p>
                                <a class="default-btn" routerLink="/contact"
                                    >Contact Us</a
                                >
                            </div>
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>
                                        Welcome to DEVU - Your Partner for
                                        Innovative Technology Solutions.
                                    </h3>
                                </div>
                                <ul class="list">
                                    <li>
                                        <i class="flaticon-tick"></i> Innovative
                                        Solutions: Tech-savvy, customized
                                        options.
                                    </li>
                                    <li>
                                        <i class="flaticon-tick"></i> Skilled
                                        Professionals: Passionate, experienced
                                        team members.
                                    </li>
                                    <li>
                                        <i class="flaticon-tick"></i>
                                        Comprehensive Services: Web, app,
                                        digital, IT solutions.
                                    </li>
                                    <li>
                                        <i class="flaticon-tick"></i>
                                        Results-Driven Approach: Measurable
                                        outcomes, ROI focus.
                                    </li>
                                </ul>
                                <p>
                                    DEVU provides Friendly Support - high-quality technical support in a friendly and professional manner - to build long-lasting relationships with clients and help them succeed in the digital age.
                                </p>
                                <a class="default-btn" routerLink="/contact"
                                    >Contact Us</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expert Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img1.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>Founder</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img2.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Williams Halimton</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img3.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Juhon Dew</h3>
                        <span>CEO</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section> -->
