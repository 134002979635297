<app-navbar-two></app-navbar-two>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Awesome</span> Services</h2>
            <p>
                Delivers high-quality services to exceed clients' expectations
                and make a positive impact on their businesses .
            </p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-it"></i>
                    </div>
                    <h3>IT Consultancy</h3>
                    <p>
                        Provides expert advice and guidance on information
                        technology to help businesses improve their performance
                        and achieve their goals.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>
                        You can have customized websites tailored to their needs
                        and requirements, using the latest technologies and
                        tools to achieve a unique and effective design and user
                        experience.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fcc774">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>App Development</h3>
                    <p>
                        You can get customized mobile applications tailored to
                        their needs and requirements, using the latest
                        technologies and tools to ensure a seamless and
                        exceptional user experience.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-shopping-cart"></i>
                    </div>
                    <h3>Wordpress & Shopify</h3>
                    <p>
                        Our website development and design services leverage the
                        power of WordPress and Shopify platforms to deliver
                        high-quality, customized websites that perfectly align
                        with our client's business requirements, enabling them
                        to establish a strong online presence and engage with
                        their target audience effectively.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>Digital Marketing</h3>
                    <p>
                        We offer a comprehensive range of digital marketing
                        services, including search engine optimization (SEO),
                        social media marketing, email marketing, pay-per-click
                        (PPC) advertising, and content marketing, to help
                        businesses effectively promote their products or
                        services online and reach their target audiences.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fe929f">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>IT Solutions</h3>
                    <p>
                        Our IT solutions are designed to offer end-to-end
                        support to businesses, from software development and
                        technical assistance to system management and IT
                        infrastructure services. Our goal is to provide our
                        clients with the resources they need to optimize their
                        business processes and achieve their technological
                        objectives.
                    </p>
                </div>
            </div>
        </div>
    </div>

   
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

