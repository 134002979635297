import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeThreeComponent } from './components/pages/home-three/home-three.component';

import { ContactComponent } from './components/pages/contact/contact.component';

import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './components/pages/error/error.component';

import { TeamComponent } from './components/pages/team/team.component';

import { ProjectsOneComponent } from './components/pages/projects-one/projects-one.component';

import { ServicesOneComponent } from './components/pages/services-one/services-one.component';
import { AboutComponent } from './components/pages/about/about.component';

const routes: Routes = [

    {path: '', component: HomeThreeComponent},

    {path: 'about', component: AboutComponent},
    {path: 'services-one', component: ServicesOneComponent},
    {path: 'projects-one', component: ProjectsOneComponent},
    {path: 'team', component: TeamComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'contact', component: ContactComponent},
    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
