<app-navbar-two></app-navbar-two>

<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Projects</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Projects</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="projects-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image1">
                        <a href="https://mostaql.com/portfolio/1395247-real-estate-websites-mobile-application-android-ios-dashboard" target="_blank">

                            <img
                            width="440"
                            height="375"
                            class="img"
                            src="assets/img/protfolio/img1.jpg"
                            alt="image"
                            />
                        </a>
                    </div>
                    <div class="content">
                        <a href="https://mostaql.com/portfolio/1395247-real-estate-websites-mobile-application-android-ios-dashboard" target="_blank">

                        <h3>Real Estate Websites</h3>
                        <span>Web Development</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a href="https://mostaql.com/portfolio/1395373-turbo-website-for-selling-and-renting-cars-framework-laravel-angular" target="_blank">
                            <img
                            class="img"
                            src="assets/img/protfolio/img2.png"
                            alt="image"
                            />
                        </a>
                </div>
                    <div class="content">
                        <a href="https://mostaql.com/portfolio/1395373-turbo-website-for-selling-and-renting-cars-framework-laravel-angular" target="_blank">
                        <h3>Turbo Website for selling and renting cars</h3>
                        <span>Web Development</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                            <img
                            class="img"
                                src="assets/img/protfolio/img3.jpg"
                                alt="image"
                        />
                    </div>
                    <div class="content">
                        <h3>Chat App Laravel back-end & angular front-end
                        </h3>
                        <span>Web Development</span>
                        <a href="https://mostaql.com/portfolio/1395289-chat-app-laravel-back-end-angular-front-end"  target="_blank" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"
                            ><img
                                src="assets/img/protfolio/img4.png"
                                alt="image"
                        /></a>
                    </div>
                    <div class="content">
                        <h3>Creative Web Develop</h3>
                        <span>React Development</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"
                            ><img
                                src="assets/img/protfolio/img5.png"
                                alt="image"
                        /></a>
                    </div>
                    <div class="content">
                        <h3>Digital Services</h3>
                        <span>E-commerce Development</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"
                            ><img
                                src="assets/img/protfolio/img6.png"
                                alt="image"
                        /></a>
                    </div>
                    <div class="content">
                        <h3>Complex Design</h3>
                        <span>Software Engineering</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>
