<app-navbar-two></app-navbar-two>

<div class="main-banner-area-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span>Software Startup</span>
                            <h1 class="wow fadeInLeft">
                                Web Application & Mobile Application
                            </h1>
                            <p>
                                <span style="color: #086ad8; font-weight: bold"
                                    >DEVU
                                </span>
                                is a software startup specializing in web
                                application and mobile application development.
                                The company leverages the latest technologies
                                and tools to deliver the best results and
                                services to its clients.
                            </p>
                            <div class="banner-btn wow zoomIn">
                                <a routerLink="/contact" class="default-btn"
                                    >Contact Us</a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img
                                src="assets/img/home-three/1.png"
                                class="wow fadeInUp"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                            <img
                                src="assets/img/home-three/2.png"
                                class="wow fadeInDown"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                            <img
                                src="assets/img/home-three/3.png"
                                class="wow fadeInUp"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                            <img
                                src="assets/img/home-three/4.png"
                                class="wow fadeInDown"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                            <img
                                src="assets/img/home-three/5.png"
                                class="wow zoomIn"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                            <img
                                src="assets/img/home-three/main.png"
                                class="wow zoomIn"
                                data-wow-delay="0.6s"
                                alt="image"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</div>

<section class="services-section bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Awesome</span> Services</h2>
            <p>
                Delivers high-quality services to exceed clients' expectations
                and make a positive impact on their businesses .
            </p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-it"></i>
                    </div>
                    <h3>IT Consultancy</h3>
                    <p>
                        Provides expert advice and guidance on information
                        technology to help businesses improve their performance
                        and achieve their goals.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>
                        You can have customized websites tailored to their needs
                        and requirements, using the latest technologies and
                        tools to achieve a unique and effective design and user
                        experience.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fcc774">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>App Development</h3>
                    <p>
                        You can get customized mobile applications tailored to
                        their needs and requirements, using the latest
                        technologies and tools to ensure a seamless and
                        exceptional user experience.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-shopping-cart"></i>
                    </div>
                    <h3>Wordpress & Shopify</h3>
                    <p>
                        Our website development and design services leverage the
                        power of WordPress and Shopify platforms to deliver
                        high-quality, customized websites that perfectly align
                        with our client's business requirements, enabling them
                        to establish a strong online presence and engage with
                        their target audience effectively.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>Digital Marketing</h3>
                    <p>
                        We offer a comprehensive range of digital marketing
                        services, including search engine optimization (SEO),
                        social media marketing, email marketing, pay-per-click
                        (PPC) advertising, and content marketing, to help
                        businesses effectively promote their products or
                        services online and reach their target audiences.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fe929f">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>IT Solutions</h3>
                    <p>
                        Our IT solutions are designed to offer end-to-end
                        support to businesses, from software development and
                        technical assistance to system management and IT
                        infrastructure services. Our goal is to provide our
                        clients with the resources they need to optimize their
                        business processes and achieve their technological
                        objectives.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>

<section class="audience-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="audience-image">
                    <img
                        class="wow fadeInUp"
                        data-wow-delay="0.4s"
                        src="assets/img/audience.png"
                        alt="image"
                    />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="audience-content">
                    <h3>
                        What sets us <span>apart</span> from other Software
                        Startup
                    </h3>
                    <div class="bar"></div>
                    <p class="wow fadeInRight">
                        <span style="color: #086ad8; font-weight: bold"
                            >DEVU
                        </span>
                        has a highly qualified and experienced team of
                        developers, designers, and support staff who work
                        together to achieve the best outcomes. The company
                        offers its services to various sectors and companies,
                        ranging from startups to large enterprises, and provides
                        customized solutions to meet the precise needs of its
                        clients.
                    </p>
                    <p class="wow fadeInLeft">
                        In summary,
                        <span style="color: #086ad8; font-weight: bold"
                            >DEVU
                        </span>
                        is a software startup that specializes in web and mobile
                        application development, uses the latest technologies
                        and tools in the development process, and offers
                        customized solutions to meet its clients' precise needs
                        effectively.
                    </p>
                    <div class="bar"></div>
                    <div class="audience-btn">
                        <a routerLink="/contact" class="default-btn"
                            >Contact Us</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>

<section class="solutions-section pb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-content-area">
                    <div class="solutions-content">
                        <h3>Our <span>Featured</span> Solutions</h3>
                        <div class="bar"></div>
                        <p>
                            Set of services and products that we offer to our
                            clients, designed to address their specific needs
                            and challenges. These solutions are tailored to meet
                            the unique requirements of each client and are
                            delivered using the latest technologies.
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon">
                            <i class="flaticon-blog"></i>
                        </div>
                        <h3>Web App Development</h3>
                        <p>
                            Provide customized and innovative web applications
                            that are designed to meet our client's unique needs
                            and enhance their online presence, utilizing the
                            latest technologies.
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-d3fbf9">
                            <i class="flaticon-cellphone"></i>
                        </div>
                        <h3>Mobile App Development</h3>
                        <p>
                            Deliver personalized and user-friendly mobile
                            applications that cater to our client's specific
                            requirements, utilizing cutting-edge technologies
                            and industry-leading practices to ensure a seamless
                            and exceptional user experience.
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-fce8c9">
                            <i class="flaticon-shopping-cart"></i>
                        </div>
                        <h3>Wordpress & Shopify</h3>
                        <p>
                            Provide customized, scalable, and user-friendly
                            e-commerce solutions that help our clients establish
                            a strong online presence and effectively manage
                            their online businesses.
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-d5e6fe">
                            <i class="flaticon-laptop"></i>
                        </div>
                        <h3>Branding & Marketing</h3>
                        <p>
                            Offer comprehensive and result-oriented strategies
                            that help our clients build a strong brand and
                            effectively reach their target audience, utilizing
                            innovative techniques and industry-leading practices
                            to achieve maximum impact and ROI.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pr-0 mt-lg-5 d-lg-block d-md-none">
                <div class="solutions-image wow pulse" data-wow-delay="0.4s">
                    <img src="assets/img/solutions.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="design-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="design-image">
                    <img src="assets/img/design.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="design-content">
                    <h3>Design <span>Development</span></h3>
                    <div class="bar"></div>
                    <p>
                        We offer design development services in various fields
                        such as web design, mobile design, digital interfaces,
                        worpress, shopify, user experience design, branding, and
                        more, to meet the diverse needs of our clients.
                    </p>
                    <ul class="design-list">
                        <li><i class="flaticon-check"></i> Web Development</li>
                        <li><i class="flaticon-check"></i> App Development</li>
                        <li><i class="flaticon-check"></i> Laravel Design</li>
                        <li><i class="flaticon-check"></i> Angular Design</li>
                        <li><i class="flaticon-check"></i> Flutter Design</li>
                        <li><i class="flaticon-check"></i> Wordpress Design</li>
                        <li><i class="flaticon-check"></i> Shopify Design</li>
                        <li>
                            <i class="flaticon-check"></i> Responsive Design
                        </li>
                        <li><i class="flaticon-check"></i> UI/UX Design</li>
                        <li><i class="flaticon-check"></i> Unique Design</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>

<section class="counter-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>
                We have Completed <span>25+</span> Projects
                <span>Successfully</span>
            </h2>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-check"></i>
                    </div>
                    <h3><span class="odometer" data-count="25">00</span></h3>
                    <p>Completed Project</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-happy"></i>
                    </div>
                    <h3><span class="odometer" data-count="18">00</span></h3>
                    <p>Happy Clients</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-technical-support"></i>
                    </div>
                    <h3><span class="odometer" data-count="2">00</span></h3>
                    <p>currently under development</p>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-trophy"></i>
                    </div>
                    <h3><span class="odometer" data-count="750">00</span></h3>
                    <p>Winning Awards</p>
                </div>
            </div> -->
        </div>
    </div>
</section>


<section class="protfolio-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Impressive</span> Portfolio</h2>
            <p>
                We showcase our impressive and diverse portfolio, which includes
                a range of successful projects we have executed for our clients
                in areas reflecting our ability to effectively meet our client's
                needs with high-quality solutions.
            </p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image1">
                        <a href="https://mostaql.com/portfolio/1395247-real-estate-websites-mobile-application-android-ios-dashboard" target="_blank">

                            <img
                            width="440"
                            height="375"
                            class="img"
                            src="assets/img/protfolio/img1.jpg"
                            alt="image"
                            />
                        </a>
                    </div>
                    <div class="content">
                        <a href="https://mostaql.com/portfolio/1395247-real-estate-websites-mobile-application-android-ios-dashboard" target="_blank">

                        <h3>Real Estate Websites</h3>
                        <span>Web Development</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a href="https://mostaql.com/portfolio/1395373-turbo-website-for-selling-and-renting-cars-framework-laravel-angular" target="_blank">
                            <img
                            class="img"
                            src="assets/img/protfolio/img2.png"
                            alt="image"
                            />
                        </a>
                </div>
                    <div class="content">
                        <a href="https://mostaql.com/portfolio/1395373-turbo-website-for-selling-and-renting-cars-framework-laravel-angular" target="_blank">
                        <h3>Turbo Website for selling and renting cars</h3>
                        <span>Web Development</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                            <img
                            class="img"
                                src="assets/img/protfolio/img3.jpg"
                                alt="image"
                        />
                    </div>
                    <div class="content">
                        <h3>Chat App Laravel back-end & angular front-end
                        </h3>
                        <span>Web Development</span>
                        <a href="https://mostaql.com/portfolio/1395289-chat-app-laravel-back-end-angular-front-end"  target="_blank" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"
                            ><img
                                src="assets/img/protfolio/img4.png"
                                alt="image"
                        /></a>
                    </div>
                    <div class="content">
                        <h3>Creative Web Develop</h3>
                        <span>React Development</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"
                            ><img
                                src="assets/img/protfolio/img5.png"
                                alt="image"
                        /></a>
                    </div>
                    <div class="content">
                        <h3>Digital Services</h3>
                        <span>E-commerce Development</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"
                            ><img
                                src="assets/img/protfolio/img6.png"
                                alt="image"
                        /></a>
                    </div>
                    <div class="content">
                        <h3>Complex Design</h3>
                        <span>Software Engineering</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

